@import url(~@deliverymates/dm-ui/theme/sabrina-staging.css);
.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-control-zoom-out,
.leaflet-control-zoom-in {
  text-decoration: none !important;
}

.leaflet-div-icon {
  background: transparent !important;
  border: 0px solid #666 !important;
}

.custom-map-icon {
  border: 0;
  background: transparent;
}

.waypoint-default {
  background: url(/images/maps/waypoint_default.svg);
  background-size: 26px 50px;
  width: 26px;
  height: 50px;
  margin-top: -23px;
}

.waypoint-active {
  background: url(/images/maps/waypoint_active.svg);
  background-size: 26px 50px;
  width: 26px;
  height: 50px;
  margin-top: -23px;
}

.waypoint-dedicated {
  background: url(/images/maps/waypoint_dedicated.svg);
  background-size: 26px 50px;
  width: 26px;
  height: 50px;
  margin-top: -23px;
}

.place-default {
  background: url(/images/maps/place_default.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.place-open {
  background: url(/images/maps/place_open.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.place-active {
  background: url(/images/maps/place_active.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.pickup-icon {
  background: url(/images/maps/pickup_map_icon.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.pickup-simple-icon {
  background: url(/images/site_address.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.delivery-icon {
  background: url(/images/maps/delivery_map_icon.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.new-delivery-icon {
  background: url(/images/map-delivery.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.delivery-open {
  background: url(/images/maps/delivery_open.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.dm-icon {
  background: url(/images/maps/default_icon.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.driver-icon {
  background: url(/images/maps/driver_icon.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.driver-icon-active {
  background: url(/images/maps/driver_active.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.driver-icon-error {
  background: url(/images/maps/driver_error.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

/* updated driver's icons */
.driver-icon-clocked_in {
  background: url(/images/maps/scooter-simple.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.driver-icon-clocked_out {
  background: url(/images/maps/scooter-noShow.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.driver-icon-available {
  background: url(/images/maps/scooter-simple.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.driver-icon-busy {
  background: url(/images/maps/scooter-busy.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

/* updated orders icons */
.pickup-icon-on_risk {
  background: url(/images/maps/pickup-onRisk.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.pickup-icon-on_hold {
  background: url(/images/maps/pickup-onHold.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.pickup-icon-open {
  background: url(/images/maps/pickup-open.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.pickup-icon-simple {
  background: url(/images/maps/pickup-simple.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.delivery-icon-on_risk {
  background: url(/images/maps/customer-onRisk.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.delivery-icon-on_hold {
  background: url(/images/maps/customer-onHold.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.delivery-icon-open {
  background: url(/images/maps/customer-open.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

.delivery-icon-simple {
  background: url(/images/maps/customer-simple.svg);
  background-size: 30px 36px;
  width: 30px;
  height: 36px;
}

html,
body {
  font-weight: 300;
}

#myCustomTrigger {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 5px 10px;
  margin: 5px;
  background: white;
  border-radius: 5px;
  background-color: #545f6d;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 9999;
}

.loadingPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ReactVirtualized__Grid:focus {
  outline: 0;
}

#jsd-widget {
  z-index: 9 !important;
  bottom: -30px !important;
  right: -25px !important;
}

.dm-panel__header {
  padding: 15px;
}
