#root > .dm-layout__app.flex-column {
  min-height: 100vh !important;
  height: auto;

  .dm-layout__app.flex-row {
    min-height: 100vh !important;
    height: auto;
  }
}

.modal_full_width h5.modal-title {
  width: 100%;
}

/* Prevent DM Modal from closing when clicking the overlay */
.modal-stepper .dm-modal {
  pointer-events: none;
}
.modal-stepper .dm-modal__dialog__content {
  pointer-events: auto;
}

.marker-cluster-small {
  background-color: rgba(241, 152, 9, 0.8) !important;
}
.marker-cluster-small div {
  background-color: rgba(241, 152, 9, 0.8) !important;
}
.marker-cluster-medium {
  background-color: rgba(12, 236, 240, 0.8) !important;
}
.marker-cluster-medium div {
  background-color: rgba(12, 236, 240, 0.8) !important;
}

.marker-cluster-large {
  background-color: rgba(77, 120, 214, 0.8) !important;
}
.marker-cluster-large div {
  background-color: rgba(77, 120, 214, 0.8) !important;
}

//stepper in CreateOrder, CreateRun, CreateStop

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  border-color: var(--primary) !important;
  color: var(--primary) !important;
}

.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title:after {
  border-color: var(--primary) !important;
}
